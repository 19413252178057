@tailwind base;
@tailwind components;
@tailwind utilities;

.red_blur {
    background-image: url("/public/red-blur.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .pink_blur {
    background-image: url("/public/pink-blur.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .teal_blur {
    background-image: url("/public/tiffany-blur.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .blue_blur {
    background-image: url("/public/p-background.png");
    background-repeat: no-repeat;
    background-size: cover;
  }